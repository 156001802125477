<template>
    <div id="account-page">
        <section class="container">
            <div class="header flex">
                <h1>Hello, {{ user.firstName }}!</h1>
            </div>

            <div class="account-info">
                <button class="button-nav" @click="toggleComponent('info')" ref="info">Profile Information</button>
                <button class="button-nav" @click="toggleComponent('make-payment')" ref="make-payment">Make Payment</button>
                <button class="button-nav" @click="toggleComponent('billing')" ref="billing">Billing History</button>
                <button class="button-nav" @click="toggleComponent('change-password')" ref="change-password">Change Password</button>
                <button class="button-nav" @click="toggleComponent('messages')" ref="messages">Messages</button>

                <ChangePassword v-if="showChangePassword"></ChangePassword>
                <BillingHistory v-if="showBillingHistory"></BillingHistory>
                <ProfileInfo v-if="showProfileInfo"></ProfileInfo>
                <MakePayment @madePayment="toggleComponent('billing')" v-if="showMakePayment"></MakePayment>
                <Messages v-if="showMessages"></Messages>

            </div>
        </section>
    </div>
</template>

<script>
import ChangePassword from '@/components/account/ChangePassword'
import BillingHistory from '@/components/account/BillingHistory'
import ProfileInfo from '@/components/account/ProfileInfo'
import MakePayment from '@/components/account/MakePayment'
import Messages from '@/components/account/Messages'
export default {
    name: 'AccountView',
    components: {
        ChangePassword,
        BillingHistory,
        ProfileInfo,
        MakePayment,
        Messages
    },
    data() {
        return {
            user: this.$store.state.user,
            showChangePassword: false,
            showProfileInfo: false,
            showBillingHistory: false,
            showMakePayment: false,
            showMessages: false
        }
    },
    mounted() {
        this.toggleComponent('info')
        this.$store.dispatch('getPaymentSources');
        this.$store.dispatch('getSubscriptions');
        this.$store.dispatch('getDefaultPayment');
        this.$store.dispatch('getSubCancellations');
    },
    methods: {
        toggleComponent(option) {
            this.setActiveClass(option)
            this.hideAllComponents();
            switch(option) {
                case 'info':
                    this.showProfileInfo = true;
                    break;
                case 'billing':
                    this.showBillingHistory = true;
                    break;
                case 'change-password':
                    this.showChangePassword = true;
                    break;
                case 'make-payment':
                    this.showMakePayment = true;
                    break;
                case 'messages':
                    this.showMessages = true;
                    break;
                default:
                    this.showProfileInfo = true;
                    break;
            }
        },
        setActiveClass(ref) {
            let options = ['billing', 'info', 'change-password', 'make-payment', 'messages'];
            options.forEach(value => {
                this.$refs[value].classList.remove('active')
            })
            this.$refs[ref].classList.add('active')
        },
        hideAllComponents() {
            this.showProfileInfo = false;
            this.showBillingHistory = false;
            this.showChangePassword = false;
            this.showMakePayment = false;
            this.showMessages = false;
        }
    }
}

</script>

<style lang="scss" scoped>
#account-page {
    h1 {
        margin:0;
    }
    
}
</style>