<template>
    <section id="pricing">
      
        <div class="container flex">
            <div class="flex-1">
                <img class="featured-work" alt="Featured Work Image" src="../../assets/code-computer.jpg">
            </div>

            <div class="flex-1">
                <h1 class="huge">$175<span>/month</span></h1>
                <h2>12 Month Contract</h2>
                <p>
                    $0 down for a standard 5 page small business website. If you need more than that then we have to do custom pricing based on the 
                    scope of work, number of additional pages, and time involved.
                </p>
                <p>
                    You own your domain, content, listing, and profiles. Cancel anytime with no fees or hassle.
                </p>
            </div>
        </div>

        <div class="price-card-list flex">

            <div class="pricing-card flex-1">
                <router-link to="/contact">
                    <div class="package-title">E-Commerce</div>
                    <img src="../../assets/ecommerce.png" alt="">
                    <div class="package-price">$15k + $250<span>/m</span></div>
                    <ul>
                        <li>Starting at $15k</li>
                        <li>WordPress or Shopify</li>
                        <li>Google Analytics/SEO</li>
                        <li>Modern Design</li>
                        <li>24/7 Support</li>
                    </ul>

                    <div class="price-action">
                        <div>Get E-Commerce</div>
                    </div>

                </router-link>
            </div>

            <div class="pricing-card flex-1">
                <router-link to="/contact">
                    <div class="package-title">Standard</div>
                    <img src="../../assets/3-stars.png" alt="">
                    <div class="package-price">$175<span>/m</span></div>
                    <ul>
                        <li>Up to 5 pages</li>
                        <li>Google Analytics/SEO</li>
                        <li>Modern Design</li>
                        <li>24/7 Support</li>
                        <li>Unlimited Changes</li>
                    </ul>

                    <div class="price-action">
                        <div>Get Standard</div>
                    </div>

                </router-link>
            </div>

            <div class="pricing-card flex-1">
                <router-link to="/contact">
                    <div class="package-title">Design</div>
                    
                    <img src="../../assets/design.png" alt="">
                    
                    <div class="package-price">$50<span>/hr</span></div>

                    <ul>
                        <li>Print/Digital Design</li>
                        <li>Logo Design</li>
                        <li>Unlimited Revisions</li>
                        <li>Fast Turnaround</li>
                        <li>Printing Ability</li>
                    </ul>

                    <div class="price-action">
                        <div>Get Design</div>
                    </div>
                </router-link>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'PricingSection'
    }
</script>

<style lang="scss" scoped>

section#pricing {
    .container {

        img {
            border-radius: 20px;
            margin:0 auto;
        }

        > div {
            padding:20px;
        }

        h1 {
            margin:0;

            &.huge {
                color:#025bf7;
            }
            span {
                color:black;
                font-size:0.6em;
            }
        }

        h2 {
            margin:0;
        }

        p {
            text-align: justify;
        }

    }

    .price-card-list {
        margin:0px 10% 60px 10%;

        .pricing-card {

            a {
                background:white;
                margin:5px;
                display: block;
                text-align: center;
                border-radius: 20px;
                padding:30px;

                &:hover {
                    box-shadow: 0px 32px 56px rgba(7,29,43,0.08);

                    .package-title{
                        color:#FF6A00;
                    }

                    .price-action {
                        background:#025bf7;
                        color:white;
                        transform: scale(1.05);

                        > div {

                            &:after {
                                opacity: 1;
                                transform: translateX(10px);
                            }
                        }
                    }

                    img {
                        transform: scale(1.2);
                    }
                }

                img {
                    margin:10px auto;
                    max-width:125px;
                    transition: all 0.1s ease-in-out;
                }

                .package-title {
                    color:black;
                    font-weight: 800;
                    font-size:1.3em;
                    text-transform: uppercase;

                    svg {
                        color:#CBA400;
                    }
                }

                .package-price {
                    font-weight:800;
                    font-size:2em;

                    span {
                        color:black;
                        font-size:0.5em;
                    }
                }

                ul {
                    max-width:250px;
                    margin:20px auto;
                    color:black;
                    padding:10px;

                    li {
                        text-align: left;
                        font-size: 16px;
                        padding:0 0 5px 0;
                        position: relative;
                        border-bottom:1px solid #e9e9e9;
                        text-indent: 25px;
                        margin-left:0;

                        &:last-of-type {
                            border-bottom:none;
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            left:25px;
                            top:5px;
                            display:block;
                            height:15px;
                            width:15px;
                            -webkit-mask: url(../../assets/square-check-regular.svg) no-repeat 50% 50%;
                            mask: url(../../assets/square-check-regular.svg) no-repeat 50% 50%;
                            -webkit-mask-size: cover;
                            mask-size: cover;
                            background-color:#FF6A00;
                        }
                    }
                }

                .price-action {
                    font-weight: 800;
                    border-radius: 20px;
                    border: 2px solid #025bf7;
                    transition: all 0.1s ease-in-out;
                    width:80%;
                    margin:0 auto;
                    padding:10px 0px;
                    box-shadow: 0px 8px 32px -16px rgba(0, 112, 246, 0.32), 0px 16px 32px -16px rgba(7, 29, 43, 0.32);

                    > div {
                        transition: all 0.3s linear;

                        &:after {
                            font-weight: 300;
                            transition: opacity 0.1s linear, transform 0.4s ease-in-out;
                            transform: translateX(-30px);
                            position: absolute;
                            content: '>>';
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1280px) {

    section#pricing {
        .price-card-list {
            .pricing-card {
                a {

                    &:hover {
                        .price-action {
                            padding:10px 20px 10px 10px;
                        }
                    }
                    .package-title {
                        font-size:1em;
                    }
                    .package-price {
                        font-size:1.5em;
                    }

                    ul {
                        li {
                            font-size:0.7em;

                            &:before {
                                top:2px;
                            }
                        }
                    }

                    .price-action {
                        font-size:0.8em;
                    }
                }
            }
        }
    }

}

@media (max-width: 960px) {

    section#pricing {
        ul {
            li {
                font-size:1em!important;
            }
        }
    }
}

@media (max-width: 640px) {

    section#pricing {
        ul {
            li {
                
            }
        }
    }
}

</style>