/* eslint-disable */
import axios from 'axios';
import store from '../store';

const url = '/api/user';
const passwordUrl = '/api/user/password';
const stripeUrl = '/api/user/stripe';
const paymentSourcesUrl = '/api/user/payment-sources';
const chargesUrl = '/api/user/charges'

class UserService {

    //Create Account
    static Create(obj) {
        return axios.post(url, {
            password: obj.password,
            email: obj.email,
            firstName: obj.firstName,
            lastName: obj.lastName,
            phone: obj.phone,
            company: obj.company,
            country: obj.country,
            address: obj.address,
            suite: obj.suite,
            city: obj.city,
            state: obj.state,
            zip: obj.zip
        });
    }

    //update user information
    static Update(userInfo) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.put(url, userInfo, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    static ChangePassword(newPassword, oldPassword) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.put(passwordUrl, { newPassword: newPassword, oldPassword: oldPassword }, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    static CreatePaymentSource(source) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.post(paymentSourcesUrl, { source: source }, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    static SetDefaultPaymentSource(source) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.put(paymentSourcesUrl, { source: source }, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    static ListPaymentSources() {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(paymentSourcesUrl, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    static StripeAccount() {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(stripeUrl, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    //Get Account Charges
    static ListCharges(obj) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(chargesUrl, { params: obj, headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

}

export default UserService;