<template>
    <div class="container">
        <h1>Logging Out...</h1>
    </div>
</template>

<script>
    export default {
        name:'LogoutView',
        mounted() {
            this.doLogout();
        },
        methods: {
            async doLogout() {
                this.$store.commit('updateState', false)
                this.$router.push({ name: 'login' })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>