/* eslint-disable */
import axios from 'axios';
import store from '../store';

const url = '/api/auth';

class AuthService {
    
    static Verify() {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(url, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    static Login(email, password) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(url, email, password);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }
}

export default AuthService;