<template>
    <section id="choose-grube">
        <div class="container flex">

            <div class="why-grube flex-1">
                <h1 class="huge">Why Choose Us?</h1>
                <h2>We know small business websites</h2>
                <p>
                    Grube Digital knows what small businesses need in a website.  Our extensive experience and customer service
                    gives us a competitive advantage in the small business sector. Most business owners do not want to directly
                    make changes to their website. They want someone else to handle the technical stuff.  When you hire us,
                    we are your website department. This leaves you time to work on other important aspects of your business.
                </p>
                <p>
                    We do not use WordPress or other content management systems for most business sites. They are overkill for
                    most small businesses and it negatively impacts site performance. We code sites by hand, starting
                    from scratch for each project. This ensures designs are modern and the site has no unnecessary code. After
                    designing and testing the site, we run it through multiple programs to ensure it is optimized for 
                    maximum performance. Faster load times equates to more retained site users and more sales.
                </p>

                <p>
                    Google Analytics is added to all sites so you can track your site visitors.  Analytics gives a glimpse of where your
                    site visitors are coming from. With this data, you can choose where to increase marketing. Also, 
                    we submit your site to Google and Bing so potential customers can find you when searching online.
                    With our 24/7 support, you can contact us directly at anytime to make changes or updates to your site.
                    There are no call centers or forwarders, you get us everytime.
                </p>

                <router-link class="button-primary" to="/about">Learn More...</router-link>
            </div>

            <div class="accordion flex-1">

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">What are your core services?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        Grube Digital specializes in web development and graphic design services. Our 
                        primary mission is to offer small businesses a chance to compete with larger 
                        businesses online. We design and make websites, web applications, logos, banners,
                        flyers, and much more.
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">Why are your prices cheaper than other agencies?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        Grube Digital is operated solely by the owner. Since there is only one employee, we are
                        able to keep costs low.  When hiring a digital agency, you are paying for
                        the extra overhead of additional employees and their time. Occasionally we recruit help
                        when the workload is large, but we do not pass these expenses onto you!
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">Where are you located and where do you service?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">  
                        We are located in Baltimore, Maryland. All development work is done in-house.  
                        Since all of our services are done remotely, there is no barrier from being able to 
                        serve customers anywhere in the world. Though currently, all communication must be in English.
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">How long does it take to get a website?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        After having a phone meeting with you, the design process begins.  If we have all of the
                        information and digital assets we need, we start making the website. This usually takes
                        up to a week, but usually much sooner. After the initial design and testing has been done,
                        we share it with you for final approval.  If there are no changes needed, it goes online
                        immediately.
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">What is the Standard package?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        <p>
                            The "Standard" package offers development of a website up to 5 pages.  One of those pages can
                            be a contact page with a form users can fill out and be sent directly to you. The package includes
                            hosting, Google Analytics, site design with photos and graphics, final site run through 
                            optimization programs for best performance, site submitted to Google and Bing, 24/7 support, 
                            and unlimited changes. This package is a contract for 12 months at $175/ month with NO hidden fees. 
                            It is a great way to get your business online or improve your current site to something more 
                            professional and modern.
                        </p>
                        <p>
                            If you are not tech saavy, don't worry. We take care of all the tech stuff for you and everything is
                            explained in a way everyone can understand. No payment is required until after we discuss your project requirements and we are ready to begin.
                        </p>
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">What is the Design package?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        If you just need a banner designed, a logo or some other graphic element just click on the 
                        "Design" package to get started. We also offer printing for an additional cost, if requested. No
                        payment is required until after we discuss your project requirements and we are ready to begin.
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">What is the E-Commerce package?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        The E-Commerce package is for businesses that want to sell products or services online.  We typically
                        use WooCommerce, a WordPress based e-commerce system. Though we can do Shopify or custom-code
                        a system as well.  We have experience with creating inventory tracking, scheduling, discount code,
                        giftcard and other e-commerce systems. Send us a message so we can get started creating what you want. No
                        payment is required until after we discuss your project requirements and we are ready to begin.
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">When and where do I pay?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        <p>
                            All payments are made securely via the Make Payment tab, when logged in to 
                            your <router-link to="/account">Account</router-link> page.
                        </p>

                        <i>Standard Package</i>
                        <p>
                            First, we will have a phone call for an initial assessment and go over your business 
                            and the requirements for the website. NO payment is required at this time.  Once development 
                            is ready to be started, you will be asked to start your subscription. Once subscribed, 
                            we immediately start on the design and implementation of the website. Since the contract 
                            is for 12 months, you will be billed when you sign up and once at the beginning of every 
                            month for the duration of the contract.
                        </p>

                        <i>All Other Packages</i>
                        <p>
                            After a call discussing your project and requirements, we will ask for payment.  Once recieved
                            we will immediately start on your project.
                        </p>

                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">Why don't you use WordPress?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        We do use WordPress for businesses that need the ability to change and edit content themselves
                        or for e-commerce websites.  However, most small businesses that are not directly engaged with
                        selling products online do not need the extra bulk that comes along with it. It slows down
                        performance and is a security risk if not properly maintained and updated. Also, the business
                        owner must learn to properly use it. Our hand coded sites are more performant and having us
                        make the edits makes it easier on you.
                    </div>
                </div>

                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">How do I contact support?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        During our regular business hours (9AM - 5PM Eastern) you may call our phone directly. If it 
                        is after hours, send us a support ticket by clicking the "Contact" button in the
                        navigation bar at the top of the website and fill out the form. This sends us a direct message 
                        and we will immediately respond.
                    </div>
                </div>


                <div class="accordion-item">
                    <div class="accordion-header" @click="toggleInfo">
                        <div class="title">What happens if your services don't meet my expectations?</div>
                        <div class="plus">+</div>
                    </div>
                    <div class="info">
                        We have all been there.  We expected something in a service or product that we did
                        not recieve. While we stand by our work, it is important to us we exceed your expectations.
                        We ask you give us call and discuss what you are unsatisfied about so we can change or improve
                        upon it. We are a small business too, not a large company.  We  WILL work something out 
                        until we are both satisfied.
                    </div>
                </div>

            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'ChooseGrubeSection',
        methods: {
            toggleInfo(event) {
                event.target.classList.toggle('active')
            }
        }
    }
</script>

<style lang="scss" scoped>
section#choose-grube {
    background:white;

    h1 {
        margin:0;
    }

    p {
        text-align: justify;
    }

    .container {
        align-items: start;

        > div {
            margin:0 30px;
        }
    }

    .accordion {
        width:100%;
    }

    .accordion-item {
        border: solid #e9e9e9;
        border-width: 1px 1px 0px 1px;
        transition: all 0.2s ease-in-out;

        &:first-of-type{
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }

        &:last-of-type {
            border-width: 1px 1px 1px 1px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }
        .accordion-header {
            position: relative;
            padding:20px;
            display: flex;
            cursor: pointer;

            &.active + .info {
                display:block;
            }
            .title {
                pointer-events: none;
                flex:5;
                font-weight: 800;
            }

            .plus {
                pointer-events: none;
                user-select: none;
                display: block;
                text-align: center;
                color:#025bf7;
                border:1px solid #025bf7;
                border-radius: 50%;
                width:27px;
                height:27px;
                font-size:18px;
            }

            &:hover {
                .title {
                    color:#025bf7;
                }
                .plus {
                    background:#025bf7;
                    color:white;
                }
            }
        }

        .info {
            display:none;
            border-top:1px solid #e9e9e9;
            padding:20px;
            text-align: justify;
        }
    }
    
}

@media (max-width: 1280px) {

    section#choose-grube {
        .why-grube {
            font-size:14px;
        }

        .accordion-header {
            .title {
                font-size:16px;
            }
        }

        .accordion-item{
            .info {
                font-size:14px;
            }
        }
    }

}

@media (max-width: 960px) {

    section#choose-grube {
        .container {

            > div {
                margin:0px;
            }
        }
        .accordion {
            margin-top:60px!important;
        }
    }
}

@media (max-width: 640px) {

    section#choose-grube {

    }
}
</style>