import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from 'secure-ls'
import User from '@/classes/User';
import UserService from '@/services/UserService';
import SubscriptionService from '@/services/SubscriptionService';

const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    authenticated: false,
    token: '',
    expires: '',
    user: new User(),
    paymentSources: [],
    subscriptions: [],
    subCancellations: [],
    defaultPayment: null,
    errorModalMsg: '',
    errorModalShow: false
  },
  plugins: [
    createPersistedState({
      key: 'gmedia',
      paths: ['authenticated', 'user', 'token', 'expires'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  getters: {
    paymentSources: state => state.paymentSources,
    subscriptions: state => state.subscriptions,
    defaultPayment: state => state.defaultPayment,
    subCancellations: state => state.subCancellations
  },
  mutations: {
    updateState(state, data){
      if(data){
        state.authenticated = data.authenticated;
        state.user.email = data.user.email;
        state.user.firstName = data.user.firstName;
        state.user.lastName = data.user.lastName;
        state.user.phone = data.user.phone;
        state.user.company = data.user.company;
        state.user.country = data.user.country;
        state.user.address = data.user.address;
        state.user.suite = data.user.suite;
        state.user.city = data.user.city;
        state.user.state = data.user.state;
        state.user.zip = data.user.zip;
      } else {
        state.authenticated = false;
        state.user = new User();
        state.token = '';
        state.expires = '';
        state.paymentSources = [];
        state.subscriptions = [];
        state.subCancellations = [];
        state.defaultPayment = null;
      }
    },
    showErrorModal(state) {
      state.errorModalShow = true;
      setTimeout(() => {
        state.errorModalShow = false;
      }, 2000)
    },
    setToken(state, data){
      state.token = data.token;
      state.expires = data.expires;
    },
    unsetToken(state) {
      state.token = '';
      state.expires = '';
    },
    updateErrorModalMsg(state, msg) {
      state.errorModalMsg = msg;
    },
    updatePaymentSources(state, sources) {
      state.paymentSources = sources;
    },
    updateSubscriptions(state, subs) {
      state.subscriptions = subs;
    },
    updateDefaultPayment(state, value) {
      state.defaultPayment = value;
    },
    updateSubCancellations(state, cancellations) {
      state.subCancellations = cancellations;
    }
  },
  actions: {
    async getDefaultPayment(context) {
      await UserService.StripeAccount()
        .then(res => {
          context.commit('updateDefaultPayment', res.data.default_source)
        })
        .catch((err) => {
          console.log(err);
        })
    },
    async getPaymentSources(context) {
      await UserService.ListPaymentSources()
        .then(res => {
          context.commit('updatePaymentSources', res.data.data)
        })
        .catch((err) => {
          console.log(err);
        })
    },
    async getSubscriptions(context) {
      await SubscriptionService.List()
        .then(res => {
          context.commit('updateSubscriptions', res.data.data)
        })
        .catch((err) => {
          console.log(err);
        })
    },
    async getSubCancellations(context) {
      let list = [];
      await SubscriptionService.ListCancellationRequests()
        .then(res => {
          res.data.forEach(value => {
            list.push(value.subscription_id)
          })
          context.commit('updateSubCancellations', list)
        })
        .catch((err) => {
          console.log(err);
        })
    }
  },
  modules: {
  }
})
