<template>
    <div id="stripe-charges">
        <h2>Billing History</h2>
        <button v-if="!showingHistory" @click="showHistory" class="button-primary" :class="{ 'button-loading': isSending }" :disabled="isSending"><span>Show Billing History</span></button>
        <div v-if="showingHistory" class="charges-list">
            <div v-if="charges.length > 0">
                <div class="charge flex" v-for="charge in charges" :key="charge.title">
                    <div>
                        <p><span>{{ this.convertDate(charge.created) }}</span></p>
                        <p>Amount: <span>${{ charge.amount_captured / 100 }}</span></p>
                    </div>
                    <div>
                        Charged to {{ charge.payment_method_details.card.brand }} ending in {{ charge.payment_method_details.card.last4 }}
                    </div>
                    <div class="text-right">
                        <a target="_blank" :href="charge.receipt_url">
                            <font-awesome-icon icon="fa-solid fa-eye" />
                        </a>
                    </div>
                </div>
                <button @click="showHistory" v-if="canListMore" class="button-primary" :class="{ 'button-loading': isSending }" :disabled="isSending"><span>Show More...</span></button>
            </div>
            <div v-else>
                You have no billing history.
            </div>
        </div>
    </div>
</template>

<script>
    import UserService from '@/services/UserService'

    export default {
        name:'StripeCharges',
        data() {
            return {
                charges: [],
                isSending: false,
                showingHistory: false,
                lastInList: null,
                canListMore: false
            }
        },
        methods: {
            convertDate(timestamp) {
                return new Date(timestamp * 1000).toLocaleTimeString("en-US")
            },
            async showHistory() {
                this.isSending = true;
                let obj = {};
                if(this.lastInList != null) {
                    obj.last = this.lastInList;
                }
                await UserService.ListCharges(obj)
                .then(list => {
                    if(list.data.data.length > 0) {
                        this.charges = this.charges.concat(list.data.data);
                        this.setPagination(list);
                    }
                    this.showingHistory = true;
                })
                .catch(err => {
                    console.log(err);
                    this.$store.commit('updateErrorModalMsg', 'Error Retrieving Charges');
                    this.$store.commit('showErrorModal')
                })
                this.isSending = false;
            },
            setPagination(list){
                this.canListMore = list.data.has_more;
                this.lastInList = list.data.data[list.data.data.length-1].id;
            }
        }
    }
</script>

<style lang="scss" scoped>
#stripe-charges {
    .charges-list {
        .charge {
            align-items: center;
            background:#cecece;
            padding:10px;
            margin:5px;
            border-radius: 20px;

            > div {
                flex:1;

                p {
                    margin:0;
                }
            }
        }
    }
}
</style>