export default class ProfileInformation {

    constructor(user) {
        if(user) {
            this.firstName.value = user.firstName;
            this.lastName.value = user.lastName;
            this.company.value = user.company;
            this.phone.value = user.phone;
            this.country.value = user.country;
            this.address.value = user.address;
            this.suite.value = user.suite;
            this.city.value = user.city;
            this.state.value = user.state;
            this.zip.value = user.zip;
        }
    }

    firstName = {
        value: null,
        error: false,
        required: true
    }
    lastName = {
        value: null,
        error: false,
        required: true
    }
    company = {
        value: null,
        error: false,
        required: false
    }
    phone = {
        value: null,
        error: false,
        required: true
    }
    country = {
        value: null,
        error: false,
        required: true
    }
    address = {
        value: null,
        error: false,
        required: true
    }
    suite = {
        value: null,
        error: false,
        required: false
    }
    city = {
        value: null,
        error: false,
        required: true
    }
    state = {
        value: null,
        error: false,
        required: true
    }
    zip = {
        value: null,
        error: false,
        required: true
    }

    serialize() {
        return {
            firstName: this.firstName.value,
            lastName: this.lastName.value,
            phone: this.phone.value,
            company: this.company.value,
            address: this.address.value,
            suite: this.suite.value,
            country: this.country.value,
            city: this.city.value,
            state: this.state.value,
            zip: this.zip.value
        }
    }
}