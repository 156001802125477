export default class Message {
    name = {
        value: '',
        error: false
    };
    email = {
        value: '',
        error: false
    };
    subject = {
        value: '',
        error: false
    };
    message = {
        value: '',
        error: false
    };
    type = {
        value: 'contact',
        error: false
    }

    serialize() {
        return {
            name: this.name.value,
            email: this.email.value,
            subject: this.subject.value,
            message: this.message.value,
            type: this.type.value
        }
    }
}