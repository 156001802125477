<template>
    <div id="billing-history">

        <StripeSubscriptions></StripeSubscriptions>
        <StripeCharges></StripeCharges>

    </div>
</template>

<script>

    import StripeSubscriptions from '@/components/account/StripeSubscriptions'
    import StripeCharges from '@/components/account/StripeCharges'

    export default {
        name: 'BillingHistory',
        components: {
            StripeSubscriptions,
            StripeCharges
        }
    }
    
</script>

<style lang="scss" scoped>

</style>