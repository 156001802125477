<template>
  <div v-if="!maintenanceMode">
      <HeaderNav />
      <ErrorModal :msg="this.$store.state.errorModalMsg" v-if="this.$store.state.errorModalShow"/>
      <router-view/>
      <FooterNav />
  </div>
  <div v-else>
    <section id="coming-soon">
      <img src="./assets/grube-digital.png" alt="Grube Digital logo">
      <h1>Coming Soon!</h1>
    </section>
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav'
  import FooterNav from '@/components/FooterNav'
  import ErrorModal from '@/components/ErrorModal'

  export default {
    components: {
      HeaderNav,
      FooterNav,
      ErrorModal
    },
    data() {
      return {
        maintenanceMode: true
      }
    }

  }
</script>

<style lang="scss">
#app {
    font-family: Poppins, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#coming-soon {
  position: fixed;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
}
</style>
