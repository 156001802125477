<template>
    <div>
        <form id="payment-form">
            <h3>Enter Card Information</h3>
            <div class="form-row">
                <label for="card-element"></label>
                <div id="card-element"></div>
                <div id="card-errors" role="alert"></div>
            </div>

            <button class="button-red" @click="onCancel">Cancel</button>
            <button @click="doAdd" class="button-primary" :class="{ 'button-loading': isSending }" :disabled="isSending"><span>Add Card</span></button>
        </form>
    </div>
</template>

<script>
    import { loadStripe } from '@stripe/stripe-js';
    import UserService from '@/services/UserService'

    export default {
        name:'StripeAddCard',
        data() {
            return {
                stripe: null,
                elements: null,
                card: null,
                isSending: false
            }
        },
        async mounted() {
            this.Initialize()
        },
        methods: {
            onCancel(e){
                e.preventDefault()
                this.$emit('close')
            },
            async Initialize() {

                let style = {
                    base: {
                        fontSize: '16px',
                        fontWeight: '600'
                    }
                }

                if(process.env.NODE_ENV === 'production') {
                    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK_LIVE)
                } else {
                    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK_DEV)
                }

                this.elements = await this.stripe.elements();
                this.card = this.elements.create('card', {style: style});
                this.card.mount('#card-element');
            },
            async doAdd(e){
                e.preventDefault();
                this.isSending = true;
                const { source, error } = await this.stripe.createSource(this.card);
                if (error) {
                    // Inform the user if there was an error
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = error.message;
                } else {
                    // Send the source to your server
                    await UserService.CreatePaymentSource(source)
                    .then(data => {
                        this.$store.dispatch('getPaymentSources');
                        this.$store.dispatch('getDefaultPayment');
                        this.$emit('close')
                    })
                    .catch(err => {
                        console.log(err);
                    })
                }
                this.isSending = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
button {
    margin:5px;
}

h3 {
    margin:0;
}

.StripeElement {
  background-color: #e5e8ea;
  padding: 8px 12px;
  border-radius: 20px;
  border: 1px solid #e5e8ea;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid #cfd1d3;
  }
}

#card-errors {
    font-size:14px;
    color:red;
}
</style>