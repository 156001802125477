<template>
    <div id="profile-info" class="flex">
        
        <AddressForm type="billing" class="billing-information"></AddressForm>

        <section class="payments">
            <h2>Payment Sources</h2>
            <div class="payment-container">
                <StripeAvailCards @selectedSource="updateSelectedSource"></StripeAvailCards>
                <div class="set-default" v-if="selectedSource != null && selectedSource != defaultPayment.id">
                    <p>Set this card as default?</p>
                    <button class="button-red-small" @click="updateSelectedSource(null)"><span>Cancel</span></button>
                    <button class="button-secondary" @click="setDefaultCard" :class="{ 'button-loading': isSending }" :disabled="isSending"><span>Set Default</span></button>
                </div>
                <button @click="showAddCard = true" v-if="!showAddCard" class="button-primary add-card-button">Add Card</button>
                <StripeAddCard class="add-card" @close="showAddCard = false" v-if="showAddCard"></StripeAddCard>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import AddressForm from '@/components/AddressForm'
    import StripeAvailCards from '@/components/account/StripeAvailCards';
    import StripeAddCard from '@/components/account/StripeAddCard';
    import UserService from '@/services/UserService'

    export default {
        name:'ProfileInfo',
        components: {
            StripeAddCard,
            StripeAvailCards,
            AddressForm
        },
        computed: mapState(['defaultPayment']),
        data() {
            return {
                user: this.$store.state.user,
                showAddCard: false,
                selectedSource: null,
                isSending: false
            }
        },
        methods: {
            updateSelectedSource(source) {
                this.selectedSource = source;
            },
            async setDefaultCard() {
                this.isSending = true;
                await UserService.SetDefaultPaymentSource(this.selectedSource)
                .then(data => {
                    this.$store.dispatch('getDefaultPayment');
                })
                .catch(err => {
                    console.log(err);
                })
                this.isSending = false;
                this.selectedSource = null;
            }
        }
    }
</script>

<style lang="scss" scoped>
#profile-info{
    .billing-information,
    section {
        flex:1;
    }

    .payments {
        margin-left:20px;

        .payment-container {
            background:#c8dfff;
            text-align: center;
            padding: 60px;
            border-radius: 20px;
        }
    }

    .add-card {
        margin:20px;
    }

    .add-card-button {
        margin-top:20px;
    }

    p {
        margin:0;
    }

    .set-default {
        background:white;
        padding:20px;
        margin:20px;
        border-radius: 20px;
        box-shadow: 0 40px 60px -20px rgba(7, 29, 43, .15);
        border:1px solid #e5e8ea;

        button {
            margin:5px;
        }
    }
}
</style>