<template>
    <section id="footer">

        <div class="container flex">
            <div class="footer-links flex-1">
                <h3>Learn More</h3>
                <hr>
                <router-link to="/">Home</router-link>
                <router-link to="/about">About</router-link>
                <router-link to="/work">Work</router-link>
                <router-link to="/services">Services</router-link>
                <router-link to="/contact">Contact</router-link>
                <router-link to="/login">Login</router-link>
            </div>

            <div class="footer-services flex-1">
                <h3>Services Offered</h3>
                <hr>
                <ul>
                    <li>Web Design</li>
                    <li>Web Development</li>
                    <li>Web Applications</li>
                    <li>E-Commerce</li>
                    <li>SEO Optimization</li>
                    <li>Print Design</li>
                    <li>Digital Design</li>
                </ul>
            </div>

            <div class="footer-logo flex-1">
                <router-link to="/">
                    <img src="../assets/grube-digital.png" alt="Grube Digital logo">
                </router-link>
                <div class="footer-copyright">
                    Copyright © 2023. All Rights Reserved.
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name:'FooterNav'
    }
</script>

<style lang="scss" scoped>
section#footer {
    background:rgba(200, 223, 255, 0.5);
    .container {
        align-items: flex-start!important;

        h3 {
            margin:0;
        }

        > div {
            margin:20px;
        }
        
        .footer-links {
            a {
                display:block;
            }
        }
        .footer-services {
            ul {
                li {

                }
            }
        }
        .footer-logo {
            align-self: center;
            text-align: right;

            img {
                max-width: 200px;
            }

            .footer-copyright {
                font-size:12px;
            }
        }
    }
}

@media (max-width: 960px) {

    section#footer {
        .container {

            > div {
                
            }
            
            .footer-links {
                margin: 0px auto;
                text-align: center;
            }
            .footer-services {
                margin: 20px auto;
                text-align: center;

                ul {
                    li{
                        margin:0;
                        &:before {
                            content:none;
                        }
                    }
                }
            }
            .footer-logo {

                img {

                }

                .footer-copyright {

                }
            }
        }
    }
}

</style>