<template>
    <div id="avail-cards">
        <div v-if="paymentSources.length > 0">
            <label class="source" :class="{default: defaultPayment != null && source.id == defaultPayment.id }" v-for="source in paymentSources" :key="source.id">
                <input type="radio" v-model="selectedPaymentSource" :value="source.id" @change="sendSelected">
                <img src="../../assets/creditcard.png" alt="">
                <p>{{ source.card.last4 }}</p>
                <p>Exp: {{ source.card.exp_month }}/{{ source.card.exp_year }}</p>
            </label>
        </div>

        <div v-else class="text-small">
            No payment options available. Please add one.
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name:'StripeAvailCards',
        computed: mapState(['paymentSources', 'defaultPayment']),
        mounted() {
            this.setSelectedPaymentSource();
        },
        data() {
            return {
                selectedPaymentSource: null,
            }
        },
        methods: {
            sendSelected() {
                this.$emit('selectedSource', this.selectedPaymentSource)
            },
            setSelectedPaymentSource() {
                if(!this.selectedPaymentSource && this.defaultPayment && this.defaultPayment.id) {
                    this.selectedPaymentSource = this.defaultPayment.id;
                    this.sendSelected();
                }
            }
        },
        watch: {
            defaultPayment(source) {
                if(source) {
                   this.selectedPaymentSource = source.id;
                    this.sendSelected(); 
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

#avail-cards {
    label.source {
        display:inline-block;
        position: relative;
        text-align: center;
        width:100px;
        background:white;
        border-radius: 10px;
        border:3px solid white;
        cursor: pointer;
        padding:5px;
        margin:2px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:has(> input:checked) {
            border:3px solid #025bf7;
        }

        &:hover,
        &:focus,
        &:active {
            border:3px solid #cecece;
        }

        &.default {

            &:before {
                content: 'Default';
                position:absolute;
                top:-18px;
                left:50%;
                transform: translateX(-50%);
                background:#FF6A00;
                color:white;
                border-radius: 6px;
                padding:2px 12px;
                font-size:10px;
            }
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        img {
            margin:0 auto;
            text-align: center;
        }

        p {
            margin:0;
            font-size: 14px;
        }
    }
}
</style>