/* eslint-disable */
import axios from 'axios';
import store from '../store';

const msgUrl = '/api/message';

class MessageService {
    
    // Create Message
    static Create(obj) {
        return axios.post(msgUrl, {
            name: obj.name,
            email: obj.email,
            subject: obj.subject,
            message: obj.message,
            type: obj.type
        });
    }

    // List messages
    static List() {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(msgUrl, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    // Mark Message as Seen
    static MarkSeen(obj) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.put(msgUrl, obj, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }
}

export default MessageService;