<template>
    <div>
        <section id="reset-password" class="container">
            <div v-if="isValid">
                <h1>Reset Password</h1>
                <form @submit="submit" v-if="!submitComplete">
                    <p>Please fill out and submit the reset password form below</p>
                    <div>
                        <input type="password" :class="{ error: formData.password.error }" v-model="formData.password.value" placeholder="Enter New Password...">
                    </div>

                    <div>
                        <input type="password" :class="{ error: formData.verify.error }" v-model="formData.verify.value" placeholder="Re-enter New Password...">
                    </div>

                    <button class="button button-primary">Complete</button>
                    <div class="errors" v-if="errors.length > 0">
                        <p v-for="(item, index) in errors" :key="index">{{ item.msg }}</p>
                    </div>
                </form>

                <div v-if="submitComplete">
                    <p>Password Updated!</p>
                    <router-link to="/login">Login</router-link>
                </div>
            </div>
            <div v-else-if="!isValid && fetchComplete">
                <h1>Invalid URL</h1>
            </div>
        </section>
    </div>
</template>

<script>
    import Validator from 'validator'
    import ResetPasswordService from '@/services/ResetPasswordService';

    export default {
        name:'ResetPasswordView',
        mounted() {
            this.checkToken();
        },
        data() {
            return {
                token: this.$route.params.token,
                fetchComplete: false,
                isValid: false,
                isSending: false,
                errors: [],
                formData: {
                    password: {
                        value: '',
                        error: false
                    },
                    verify: {
                        value: '',
                        error: false
                    }
                },
                submitComplete: false
            }
        },
        methods: {
            async checkToken() {
                await ResetPasswordService.VerifyPasswordResetTokenExists(this.token)
                .then(valid => {
                    this.isValid = true;
                    this.fetchComplete = true;
                })
                .catch(err => {
                    this.isValid = false;
                    this.fetchComplete = true;
                })
            },
            async submit(e) {
                e.preventDefault();
                this.isSending = true;
                this.resetErrors()
                this.validateForm()
                if (!this.hasErrors()) {
                    this.sendData()
                } else {
                    this.showErrors()
                }
            },
            validateForm() {
                if(this.formData.password.value == null || this.formData.password.value == '') {
                    this.errors.push({ field: 'password', msg: 'Password must be filled out.' })
                }
                if(!Validator.isStrongPassword(this.formData.password.value)) {
                    this.errors.push({ field: 'password', msg: 'Password is not strong enough.' })
                }
                if(this.formData.verify.value == null || this.formData.verify.value == '') {
                    this.errors.push({ field: 'verify', msg: 'Verification must be filled out.' })
                }
                if(this.formData.password.value !== this.formData.verify.value) {
                    this.errors.push({ field: 'verify', msg: 'Passwords do not match.' })
                }
            },
            hasErrors() {
                if (this.errors.length > 0) {
                    return true;
                }
                return false;
            },
            showErrors() {
                let _this = this;
                this.isSending = false;
                this.errors.forEach(err => {
                    if (_this.formData.hasOwnProperty(err.field)) {
                        _this.formData[err.field].error = true;
                    }
                })
            },
            resetErrors() {
                this.errors = [];
                let _this = this;
                Object.keys(this.formData).forEach(key => {
                    _this.formData[key].error = false;
                })
            },
            async sendData() {
                let response = await ResetPasswordService.ResetPassword(this.token, this.formData.password.value)
                if(response.data.updated) {
                    this.isSending = false;
                    this.submitComplete = true;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>