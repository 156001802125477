<template>
  <div class="home">

    <IntroSection />

    <FeaturesSection />

    <PricingSection />

    <ChooseGrubeSection />

  </div>
</template>

<script>

import IntroSection from '@/components/home/IntroSection.vue'
import FeaturesSection from '@/components/home/FeaturesSection.vue'
import PricingSection from '@/components/home/PricingSection.vue'
import ChooseGrubeSection from '@/components/home/ChooseGrubeSection.vue'

export default {
  name: 'HomeView',
  components: {
    IntroSection,
    FeaturesSection,
    PricingSection,
    ChooseGrubeSection
  }

}
</script>

<style lang="scss" scoped>

</style>
