<template>
    <div>
        <section id="forgot-password" class="container">
            <h1>Forgot Password?</h1>
            <form @submit="submit" v-if="!completed">
                <div>
                    <p>Enter the email address associated with your account and we'll send you a link to reset your password.</p>
                    <input type="email" v-model="formData.email.value">
                </div>
                
                <button class="button button-primary">Continue</button>
                
                <div class="errors" v-if="errors.length > 0">
                    <p v-for="(item, index) in errors" :key="index">{{ item.msg }}</p>
                </div>
            </form>

            <div v-if="completed">
                Please check the associated email account for a password reset link.
            </div>

        </section>
    </div>
</template>

<script>
    import Validator from 'validator'
    import ResetPasswordService from '@/services/ResetPasswordService'
    
    export default {
        name: 'ForgotPassword',
        data() {
            return {
                formData: {
                   email: {
                        value: '',
                        errors: false
                    } 
                },
                completed: false,
                isSending: false,
                errors: []
            }
        },
        methods: {
            async submit(e) {
                e.preventDefault();
                this.isSending = true;
                this.resetErrors()
                this.validateForm()
                if (!this.hasErrors()) {
                    this.sendData()
                } else {
                    this.showErrors()
                }
            },
            validateForm(){
                if (!Validator.isEmail(this.formData.email.value)) {
                    this.errors.push({ field: 'email', msg: 'Email must be filled out and valid.' })
                }
            },
            hasErrors() {
                if(this.errors.length > 0) {
                    return true;
                }
                return false;
            },
            showErrors(){
                let _this = this;
                this.isSending = false;
                this.errors.forEach(err => {
                    if(_this.formData.hasOwnProperty(err.field)){
                        _this.formData[err.field].error = true;
                    }
                })
            },
            resetErrors() {
                this.errors = [];
                let _this = this;
                Object.keys(this.formData).forEach(key => {
                    _this.formData[key].error = false;
                })
            },
            async sendData(){
                this.isSending = false;
                await ResetPasswordService.GeneratePasswordResetToken(this.formData.email.value)
                .then(()=> {
                    this.completed = true;
                })
                .catch(err => {
                    let _this = this;
                    console.log(err);
                    if (Object.keys(err.response.data).length > 0) {
                        Object.keys(err.response.data).forEach(errorKey => {
                            if (err.response.data[errorKey] != '') {
                                _this.errors.push({ field: errorKey, msg: err.response.data[errorKey] })
                            }
                            _this.showErrors()
                        })
                    } else {
                        _this.errors.push({ msg: 'Error Processing Password Reset. Please contact us directly.' })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>