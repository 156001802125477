/* eslint-disable */
import axios from 'axios';
import store from '../store';

const url = '/api/invoice';
const payUrl = '/api/invoice/pay';

class InvoiceService {

    // Create Invoice
    static Create(obj) {
        return axios.post(url, {
            customerId: obj.customerId,
            title: obj.name,
            description: obj.email
        });
    }

    // Get all invoices
    static List(obj) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(url, {params: obj, headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    // Pay invoice
    static Pay(obj) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.post(payUrl, obj, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

}

export default InvoiceService;