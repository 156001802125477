<template>
    <div>
        <form @submit="changePassword" v-if="!formSent">
            <h2>Change Password</h2>
            <div>
                <label>Old Password</label>
                <input type="password" v-model="oldPassword">
                <p class="errors" v-if="errors && errors.oldPassword">{{ errors.oldPassword }}</p>
            </div>
            <div>
                <label>New Password</label>
                <p class="small-note">Password must contain at least 8 characters, 1 symbol, 1 uppercase and 1 lowercase.</p>
                <input type="password" v-model="newPassword">
                <p class="errors" v-if="errors && errors.newPassword">{{ errors.newPassword }}</p>
            </div>
            <div>
                <label>Confirm New Password</label>
                <input type="password" v-model="newPasswordConfirm">
                <p class="errors" v-if="errors && errors.newPasswordConfirm">{{ errors.newPasswordConfirm }}</p>
            </div>
            <button class="button-primary" :class="{ 'button-loading': isSending }" :disabled="isSending"><span>Change Password</span></button>
        </form>

        <img class="message-sent-img" src="../../assets/3-stars.png" alt="" v-if="formSent" ref="messageSentImg">
        <p class="message-sent" v-if="formSent" ref="messageSent">
            Password Changed!
        </p>

    </div>
</template>

<script>
    import UserService from '@/services/UserService';
    export default {
        name: 'ChangePassword',
        data() {
            return {
                isSending: false,
                formSent: false,
                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
                errors: {
                    oldPassword: '',
                    newPassword: '',
                    newPasswordConfirm: ''
                }
            }
        },
        methods: {
            async changePassword(e) {
                e.preventDefault();
                this.resetErrors();
                let isError = false;
                if (this.oldPassword.replace(/\s+/g, '') == '') {
                    isError = true;
                    this.errors.oldPassword = 'Old Password is required.';
                }
                if (this.newPassword.replace(/\s+/g, '') == '') {
                    isError = true;
                    this.errors.newPassword = 'New Password is required.';
                }
                if (this.newPasswordConfirm.replace(/\s+/g, '') == '') {
                    isError = true;
                    this.errors.newPasswordConfirm = 'Confirm New Password is required.';
                }
                if (this.newPassword != this.newPasswordConfirm) {
                    isError = true;
                    this.errors.newPassword = 'Passwords do not match.';
                }

                if (!isError) {
                    this.isSending = true;
                    await UserService.ChangePassword(this.newPassword, this.oldPassword)
                        .then(res => {
                            //this.$store.commit('showLoader', false);
                            if (res.data.updated) {
                                this.oldPassword = '';
                                this.newPassword = '';
                                this.newPasswordConfirm = '';
                                this.formSent = true;
                                let _this = this;
                                setTimeout(function () {
                                    _this.$refs.messageSent.classList.add('active')
                                    _this.$refs.messageSentImg.classList.add('active')
                                }, 10)
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            this.errors = err.response.data;
                        })
                        this.isSending = false;
                }
            },
            resetErrors() {
                let _this = this;
                Object.keys(this.errors).forEach(errorKey => {
                    _this.errors[errorKey] = '';
                })
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>