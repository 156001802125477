<template>
    <div id="make-payment-page">
        <h2>Subscribe to Service</h2>
        <div class="sub-list">
            <div v-if="products.length > 0">
                <div v-for="prod in products" :key="prod.id">
                    <div class="sub-card" v-if="prod.default_price && prod.default_price.type === 'recurring'">
                        <div class="sub-title">
                            <p><strong>{{ prod.name }}</strong></p>
                            <p class="text-small">Price: <span>${{ prod.default_price.unit_amount / 100 }} /{{ prod.default_price.recurring.interval }}</span></p>
                        </div>
                        <div class="sub-action">
                            <button @click="doPaymentPopup(prod.default_price, prod.name, true)" class="button-primary">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                No subscriptions available.
            </div>
        </div>

        <h2>Pay Invoice</h2>
        <div class="invoice-list" v-if="invoices.length > 0">
            <div v-for="invoice in invoices" :key="invoice.id">
                <div class="invoice-card">
                    <div class="invoice-title">
                        <p><strong>{{ invoice.title }}</strong></p>
                        {{ invoice.id }}
                        <p class="text-small">Price: <span>${{ invoice.amount / 100 }}</span></p>
                    </div>
                    <div class="invoice-action">
                        <span v-if="invoice.paidDate">Paid: {{ invoice.paidDate }}</span>
                        <button class="button-primary" @click="doPaymentPopup(invoice, invoice.title, false)">Pay Now</button>
                    </div>
                </div>
            </div>
            <button @click="showMoreInvoices" v-if="canListMore" class="button-primary" :class="{ 'button-loading': isSending }" :disabled="isSending"><span>Show More...</span></button>
        </div>
        <div v-else>
            Currently no invoices.
        </div>

        <PaymentConfirmPopup @onPaymentMade="onPaymentCompleted" @close="closePaymentPopup" :isSubscription="isSubscription" :price="currentPriceSelected" :name="currentNameSelected" v-if="showPaymentPopup"></PaymentConfirmPopup>

    </div>
</template>

<script>
    import InvoiceService from '@/services/InvoiceService'
    import SubscriptionService from '@/services/SubscriptionService'
    import PaymentConfirmPopup from '@/components/PaymentConfirmPopup'

    export default {
        name:'AccountMakePayment',
        components: {
            PaymentConfirmPopup
        },
        async mounted(){

            let products = await SubscriptionService.ListProducts();
            let invoices = await InvoiceService.List({page: 1});
            
            this.products = products.data;
            this.invoices = invoices.data.docs;
            this.setInvoicePagination(invoices);
        },
        data() {
            return {
                user: this.$store.state.user,
                isSending: false,
                nextPage: 1,
                canListMore: false,
                products: [],
                invoices: [],
                showPaymentPopup: false,
                currentPriceSelected: {},
                currentNameSelected: '',
                isSubscription: false
            }
        },
        methods: {
            doPaymentPopup(price, name, isSubscription) {
                this.currentPriceSelected = price;
                this.currentNameSelected = name;
                this.isSubscription = isSubscription;
                this.showPaymentPopup = true;
            },
            closePaymentPopup() {
                this.showPaymentPopup = false;
            },
            async onPaymentCompleted() {
                if(this.isSubscription) {
                    this.$emit('madePayment')
                }
                let invoices = await InvoiceService.List({ page: 1 });
                this.invoices = invoices.data.docs;
                this.setInvoicePagination(invoices);
            },
            async showMoreInvoices() {
                this.isSending = true;
                let obj = {};
                if(this.canListMore) {
                    obj.page = this.nextPage;
                }
                await InvoiceService.List(obj)
                .then(list => {
                    if(list.data.docs.length > 0) {
                        this.invoices = this.invoices.concat(list.data.docs);
                        this.setInvoicePagination(list);
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.$store.commit('updateErrorModalMsg', 'Error Retrieving Invoices');
                    this.$store.commit('showErrorModal')
                })
                this.isSending = false;
            },
            setInvoicePagination(list){
                this.canListMore = list.data.hasNextPage;
                this.nextPage = list.data.nextPage;
            }
        }
    }
</script>

<style lang="scss" scoped>
#make-payment-page {
    .sub-list {
        .sub-card {
            background:#cecece;
            display: flex;
            align-items: center;
            margin: 5px;
            padding:25px;
            border-radius:20px;

            p {
                margin:0;
            }

            > div {
                flex:1;
            }

            .sub-action {
                text-align: right;
                margin-right:20px;
            }
        }
    }

    .invoice-list {
        .invoice-card {
            background:#cecece;
            display: flex;
            align-items: center;
            margin: 5px;
            padding:25px;
            border-radius:20px;

            p {
                margin:0;
            }

            > div {
                flex:1;
            }

            .invoice-action {
                text-align: right;
                margin-right:20px;
            }
        }
    }
}
</style>