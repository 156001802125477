<template>
    <div id="stripe-subscriptions">
        <h2>Current Subscriptions</h2>
        <div class="subs-list">
            <div v-if="subscriptions.length > 0">
                <div class="sub-card" v-for="sub in subscriptions" :key="sub.id">
                    <div>
                        <h3>{{ sub.plan.product.name }}</h3>
                        <p>Price: <span>${{ sub.plan.amount / 100 }}/{{ sub.plan.interval }}</span></p>
                        <div class="card-onfile">
                            <div>
                                <img src="../../assets/creditcard.png" alt=""> 
                            </div>
                            <div>
                                <div v-if="sub.default_payment_method != null">
                                    <p>{{ sub.default_payment_method.card.last4 }}</p>
                                    <p>Exp: {{ sub.default_payment_method.card.exp_month }}/{{ sub.default_payment_method.card.exp_year }} </p>
                                </div>
                                <div v-if="sub.default_payment_method == null && defaultPayment != null">
                                    <p>{{ defaultPayment.card.last4 }}</p>
                                    <p>Exp: {{ defaultPayment.card.exp_month }}/{{ defaultPayment.card.exp_year }} </p>
                                </div>
                                <div v-if="sub.default_payment_method == null && defaultPayment == null">
                                    <p>No Card on File</p>
                                </div>
                            </div>
                        </div>
                        <button v-if="!isPending(sub.id)" @click="doShowCancelPopup(sub)" class="button-red">Request to Cancel</button>
                        <button v-if="isPending(sub.id)" class="button-red" disabled>Pending Cancellation</button>
                    </div>
                </div>
            </div>
            <div v-else>
                You have no active subscriptions.
            </div>
        </div>

        <StripeCancelSub @close="showCancelPopup = false" :sub="selectedSub" v-if="showCancelPopup"></StripeCancelSub>

    </div>
</template>

<script>

    import StripeCancelSub from '@/components/account/StripeCancelSub';
    import { mapState } from 'vuex';

    export default {
        name:'StripeSubscriptions',
        components: {
            StripeCancelSub
        },
        data() {
            return {
                showCancelPopup: false,
                selectedSub: null,
                subscriptions: this.$store.state.subscriptions
            }
        },
        computed: mapState(['subCancellations', 'defaultPayment']),
        methods: {
            doShowCancelPopup(sub) {
                this.selectedSub = sub;
                this.showCancelPopup = true;
            },
            isPending(id) {
                return this.subCancellations.includes(id);
            }
        }
    }
</script>

<style lang="scss" scoped>
#stripe-subscriptions {
    .subs-list {
        .sub-card {
            display: inline-block;
            margin:10px;
            padding:10px;
            background:#cecece;
            border-radius:20px;

            p, 
            h3{
                margin:0;
            }

            p {
                font-size:12px;
            }

            .card-onfile {
                margin:10px 0px;
                display:flex;
                align-items: center;

                > div {
                    padding:2px;
                }
            }

            button.button-red{
                font-size:10px;
                box-shadow:none;
                font-weight: 300;

                &:hover,
                &:active,
                &:focus {
                    box-shadow: none;
                    transform: none;
                }
            }
        }
    }
}
</style>