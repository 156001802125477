<template>
    <div id="cancel-subscription">
        <div class="cancel-container">
            <div class="initial" v-if="!cancelSent">
                <p>Are you sure you want to request to cancel this subscription?</p>
                <button @click="doClose" class="button-red">Back</button>
                <button @click="sendCancelRequest" class="button-primary" :class="{ 'button-loading': isSending }"><span>Yes, Cancel</span></button>
            </div>

            <div class="afterConfirmed" v-if="cancelSent && !alreadyPending">
                <p>We have received your request to cancel. It may take up to 24 hours for the cancellation to take effect.</p>
                <button @click="doClose" class="button-red">Close</button>
            </div>

            <div class="afterConfirmed" v-if="cancelSent && alreadyPending">
                <p>You have already submitted a cancellation request for this subscription!</p>
                <button @click="doClose" class="button-red">Close</button>
            </div>

            <div class="errors" v-if="errors.length > 0">
                <p v-for="(item, index) in errors" :key="index">{{ item.msg }}</p>
            </div>

        </div>
    </div>
</template>

<script>
    import SubscriptionService from '@/services/SubscriptionService'

    export default {
        name:'StripeCancelSub',
        props:['sub'],
        data() {
            return {
                errors: [],
                cancelSent: false,
                alreadyPending: false,
                isSending: false
            }
        },
        methods: {
            doClose() {
                this.$emit('close')
            },
            async sendCancelRequest(){
                this.errors = [];
                this.isSending = true;
                let obj = {
                    subscription_id: this.sub.id,
                    subscription_customer_id: this.sub.customer
                }
                await SubscriptionService.RequestCancellation(obj)
                .then(data => {
                    this.$store.dispatch('getSubCancellations');
                    this.cancelSent = true;
                })
                .catch(err => {
                    if(err.response.data.code === 11000) {
                        this.alreadyPending = true;
                        this.cancelSent = true;
                    } else {
                        this.errors.push({ msg: 'Error submitting cancellation. Please contact us directly or try again.' })
                    }
                })
                this.isSending = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
#cancel-subscription {
    background:rgba(0,0,0,0.8);
    top:0;
    bottom:0;
    left:0;
    right:0;
    position: fixed;
    z-index:1000;
    text-align: center;

    .cancel-container {
        position: fixed;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%);
        width:60%;
        background:white;
        z-index:100;
        padding:20px;
        border-radius: 20px;

        button {
            margin:5px;
        }
    }
}
</style>