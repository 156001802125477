<template>
    <div id="register-page">
        <section id="login" class="container">
            <h1>Register</h1>
            <form @submit="doRegister">

                <div>
                    <input type="text" :class="{ error: formData.firstName.error }" v-model="formData.firstName.value" placeholder="First Name" ref="firstName">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.lastName.error }" v-model="formData.lastName.value" placeholder="Last Name" ref="lastName">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.phone.error }" v-model="formData.phone.value" placeholder="Phone" ref="phone">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.email.error }" v-model="formData.email.value" placeholder="Email" ref="email">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.company.error }" v-model="formData.company.value" placeholder="Company Name" ref="company">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.country.error }" v-model="formData.country.value" placeholder="Country" ref="country">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.address.error }" v-model="formData.address.value" placeholder="Address" ref="address">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.suite.error }" v-model="formData.suite.value" placeholder="Suite/Apt" ref="suite">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.city.error }" v-model="formData.city.value" placeholder="City" ref="city">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.state.error }" v-model="formData.state.value" placeholder="State" ref="state">
                </div>

                <div>
                    <input type="text" :class="{ error: formData.zip.error }" v-model="formData.zip.value" placeholder="Zip Code" ref="zip">
                </div>

                <div>
                    <input type="password" :class="{ error: formData.password.error }" v-model="formData.password.value" placeholder="Password" ref="password">
                </div>

                <div>
                    <input type="password" :class="{ error: formData.passwordVerify.error }" v-model="formData.passwordVerify.value" placeholder="Verify Password" ref="passwordVerify">
                </div>

                <button class="button-primary" :class="{ 'button-loading': isSending }"><span>Register</span></button>

                <div class="errors" v-if="errors.length > 0">
                    <p v-for="(item, index) in errors" :key="index">{{ item.msg }}</p>
                </div>

            </form>
            <p class="small">Already have an account? <router-link to="/login">Login</router-link></p>
        </section>
    </div>
</template>

<script>
    import Validator from 'validator'
    import UserService from '@/services/UserService';
    export default {
        name:'RegisterView',
        data() {
            return {
                errors: [],
                isSending: false,
                formData: {
                    firstName: {
                        value: '',
                        error: false,
                        required: true
                    },
                    lastName: {
                        value: '',
                        error: false,
                        required: true
                    },
                    phone: {
                        value: '',
                        error: false,
                        required: true
                    },
                    company: {
                        value: '',
                        error: false,
                        required: false
                    },
                    email: {
                        value: '',
                        error: false,
                        required: true
                    },
                    address: {
                        value: '',
                        error: false,
                        required: true
                    },
                    suite: {
                        value: '',
                        error: false,
                        required: false
                    },
                    country: {
                        value: '',
                        error: false,
                        required: true
                    },
                    city: {
                        value: '',
                        error: false,
                        required: true
                    },
                    state: {
                        value: '',
                        error: false,
                        required: true
                    },
                    zip: {
                        value: '',
                        error: false,
                        required: true
                    },
                    password: {
                        value: '',
                        error: false,
                        required: true
                    },
                    passwordVerify: {
                        value: '',
                        error: false,
                        required: true
                    }
                }
            }
        },
        methods: {
            doRegister(e) {
                e.preventDefault();
                this.isSending = true;
                this.resetErrors()
                this.validateForm()
                if (!this.hasErrors()) {
                    this.sendData()
                } else {
                    this.showErrors()
                }
            },
            validateForm() {
                let email = Validator.escape(this.formData.email.value).trim();
                
                for(let key in this.formData) {
                    let value = this.formData[key];
                    if(value.required && (value.value.trim() == '' || value.value == null)) {
                        this.errors.push({ field: key, msg: key + ' must be filled out.' })
                    }
                }
                if(!Validator.isEmail(email)) {
                    this.errors.push({ field: 'email', msg: 'Email not valid.' })
                }
                if(!Validator.isStrongPassword(this.formData.password.value)) {
                    this.errors.push({ field: 'password', msg: 'Password not strong enough.' })
                }
                if(this.formData.password.value !== this.formData.passwordVerify.value) {
                    this.errors.push({ field: 'verifyPassword', msg: 'Passwords do not match.' })
                }
            },
            hasErrors() {
                if(this.errors.length > 0) {
                    return true;
                }
                return false;
            },
            showErrors(){
                let _this = this;
                this.isSending = false;
                this.errors.forEach(err => {
                    if(_this.formData.hasOwnProperty(err.field)){
                        _this.formData[err.field].error = true;
                    }
                })
            },
            resetErrors() {
                this.errors = [];
                let _this = this;
                Object.keys(this.formData).forEach(key => {
                    _this.formData[key].error = false;
                })
            },
            serializeFormData() {
                return {
                    firstName: this.formData.firstName.value,
                    lastName: this.formData.lastName.value,
                    phone: this.formData.phone.value,
                    company: this.formData.company.value,
                    email: this.formData.email.value,
                    address: this.formData.address.value,
                    suite: this.formData.suite.value,
                    country: this.formData.country.value,
                    city: this.formData.city.value,
                    state: this.formData.state.value,
                    zip: this.formData.zip.value,
                    password: this.formData.password.value
                }
            },
            async sendData() {
                await UserService.Create(this.serializeFormData())
                .then((res) => {
                    this.isSending = false;
                    if (res.status == 201) {
                        if (res.data.token) {
                            let obj = {
                                token: res.data.token,
                                expires: res.data.expires
                            };
                            this.$store.commit('setToken', obj);
                        }
                        this.$router.push({ name: 'account' })
                    }
                })
                .catch(err => {
                    this.isSending = false;
                    let _this = this;
                    if(Object.keys(err.response.data).length > 0) {
                        Object.keys(err.response.data).forEach(errorKey => {
                            if (err.response.data[errorKey] != '') {
                                _this.errors.push({ field: errorKey, msg: err.response.data[errorKey] })
                            }
                            _this.showErrors()
                        })
                    } else {
                        _this.errors.push({ msg: 'Error Processing Registration. Please contact us directly.' })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>