import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import WorkView from '../views/WorkView.vue'
import ServicesView from '../views/ServicesView.vue'
import LoginView from '../views/LoginView.vue'
import ContactView from '../views/ContactView.vue'
import RegisterView from '../views/RegisterView.vue'
import AccountView from '../views/AccountView.vue'
import LogoutView from '../views/LogoutView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView'
import ResetPasswordView from '../views/ResetPasswordView'
import store from '../store'
import AuthService from '@/services/AuthService'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/work',
    name: 'work',
    component: WorkView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView
  },
  {
    path: '/resetpassword/:token',
    name: 'reset-password',
    component: ResetPasswordView
  }
]

const router = createRouter({
  scrollBehavior() {
    return { top: 0 }
  },
  history: createWebHistory(),
  routes
})


router.beforeEach(async (to, from) => {
  const canAccess = await canUserAccess(to, from)
  if (!canAccess && to.name !== 'login') return '/login'
  if (!canAccess && to.name === 'login') return '/account'
});

async function checkAuthentication() {
  return await AuthService.Verify()
    .then((res) => {
      store.commit('updateState', res.data);
      return res.data.authenticated;
    })
    .catch(() => {
      store.commit('updateState', false);
      return false;
    })
}

async function canUserAccess(to) {
  switch (to.name) {
    case 'home':
      return true;
    case 'about':
      return true;
    case 'work':
      return true;
    case 'services':
      return true;
    case 'contact':
      return true;
    case 'reset-password':
      if (store.state.authenticated) {
        return false;
      }
      return true;
    case 'forgot-password':
      if (store.state.authenticated) {
        return false;
      }
      return true;
    case 'login':
      if (store.state.authenticated) {
        return false;
      }
      return true;
    case 'register':
      if (store.state.authenticated) {
        return false;
      }
      return true;
    case 'account':
      if (await checkAuthentication()) {
        return true;
      }
      return false;
    case 'logout':
      if (await checkAuthentication()) {
        return true;
      }
      return false;
    default:
      return false;
  }
}

export default router
