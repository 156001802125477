<template>
    <section id="features">

        <div class="container">
            <h1 class="huge text-center">What's Included?</h1>

            <div class="features-list">

                <div class="row">
                    <div class="feature">
                        <img src="../../assets/server-icon.png" alt="">
                        <div>
                            <h2>Hosting</h2>
                            <p>Hosting fees are included in the monthly subscription fee.</p>
                        </div>
                    </div>

                    <div class="feature">
                        <img src="../../assets/pen-square-icon.png" alt="">
                        <div>
                            <h2>Unlimited Changes</h2>
                            <p>Contact us anytime during your subcription and we will make the edits you want.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="feature">
                        <img src="../../assets/gauge-icon.png" alt="">
                        <div>
                            <h2>Optimized Website</h2>
                            <p>We use professional speed testers and website grading software for maximum performance.</p>
                        </div>
                    </div>

                    <div class="feature">
                        <img src="../../assets/phone-volume-icon.png" alt="">
                        <div>
                            <h2>24/7 Support</h2>
                            <p>Contact us directly anytime with questions or concerns.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="feature">
                        <img src="../../assets/phone-icon.png" alt="">
                        <div>
                            <h2>Responsive Design</h2>
                            <p>Built with mobile devices and desktops in mind.</p>
                        </div>
                    </div>

                    <div class="feature">
                        <img src="../../assets/send-icon.png" alt="">
                        <div>
                            <h2>Contact Forms</h2>
                            <p>Recieve business inquiries through your new site.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="feature">
                        <img src="../../assets/seo-icon.png" alt="">
                        <div>
                            <h2>Local SEO</h2>
                            <p>Includes registration on major search engines and SEO best practices to drive local traffic.</p>
                        </div>
                    </div>

                    <div class="feature">
                        <img src="../../assets/code-icon.png" alt="">
                        <div>
                            <h2>Design & Development</h2>
                            <p>Coded from scratch. Includes all design, implementation and testing.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
section#features {
    background: white;

    .features-list {

        .feature {
            flex:1;
            max-width:400px;
            display:flex;
            padding:20px;
            margin:15px;
            position: relative;
            background:#c8dfff;
            border-radius: 20px;
            
            h2 {
                font-size:1em;
                margin-bottom:10px;
                margin-top:0px;
            }

            img {
                height: 50px;
                width: 50px;
                margin-right:25px;
            }

            p {
                margin:0;
            }

        }
    }
}

@media (max-width: 1280px) {

    section#features {
        .features-list {

            .feature {
                p {
                    font-size:14px;
                }
            }
        }
    }

}

@media (max-width: 960px) {

    section#features {
        .features-list {

            .feature {

                h2 {
                    font-size:0.8em;
                }
            }
        }
    }
}

@media (max-width: 640px) {

    section#features {

        .row {
            flex-direction: column;
            justify-content: none;
        }
        .features-list {

            .feature {
                flex-direction: column;

                img {
                    margin:10px auto;
                }

                h2 {
                    text-align: center;
                }

                p {
                    font-size:14px;
                    text-align: center;
                }
            }
        }
    }
}

</style>