<template>
    <div id="messages">
        <div v-if="messages.length > 0">
            <div class="message-card" 
                v-for="message in messages" 
                @click="setSelectedMessage(message)" 
                :class="{ new: !message.seen, selected: message == selectedMessage }"
                :key="message.id">
                <div class="msg-sender">From: <span>{{ message.name }}</span></div>
                <div class="msg-subject">Subject: <span>{{ message.subject }}</span></div>
                <div v-if="message == selectedMessage">
                    <div class="msg-body"> <hr/>{{ message.message }}</div>
                </div>
            </div>
        </div>
        <div v-else class="no-message">
            No messages.
        </div>
    </div>
</template>

<script>
    import MessageService from '@/services/MessageService'

    export default {
        name: 'MessageList',
        async mounted() {
            await this.getMessages();
        },
        data() {
            return {
                messages: [],
                selectedMessage: null
            }
        },
        methods: {
            async getMessages() {
                await MessageService.List()
                .then(res => {
                    this.messages = res.data;
                })
                .catch(err => {
                    console.log('error retrieving messages');
                })
            },
            async setSelectedMessage(message) {
                this.selectedMessage = message;

                if(this.selectedMessage.seen) {
                    return;
                }

                await MessageService.MarkSeen({ id: this.selectedMessage._id })
                .then(res => {
                    this.selectedMessage.seen = true;
                })
                .catch(err => {
                    console.log(err);
                })
            }
        }

    }
</script>

<style lang="scss" scoped>
#messages {
    margin:40px auto;
    width:80%;
}
.message-card {
    margin:10px 0;
    background:$brandGray;
    border-color:#e5e8ea;
    border-width: 1px;
    padding:5px;
    border-style: solid;
    cursor: pointer;
    transition: all 0.2s linear;
    position: relative;

    &:last-of-type {
        border-width: 1px;
    }

    &:hover {
        border-color:#cfd1d3;
    }

    &.selected {
        background:$brandSkyBlue;
        border:1px solid $brandPrimary;
    }

    &.new {
        &:before {
            content:'NEW';
            background:$brandPrimary;
            position: absolute;
            right:10px;
            top:8px;
            font-size:12px;
            font-weight: 800;
            color:white;
            padding:5px 10px;
            border-radius: 20px;
        }
    }

    .msg-sender,
    .msg-subject {
        font-size:12px;
        text-transform: uppercase;
        span {
           font-weight: 800; 
        }
    }
    
    
}
</style>