<template>
    <nav class="flex">
        
        <div class="brand">
            <router-link to="/">
                <img class="logo" alt="Grube Digital logo" src="../assets/grube-digital.png">
            </router-link>
        </div>

        <div class="links flex">
            <div class="primary">
                <router-link to="/">Home</router-link>
                <router-link to="/about">About</router-link>
                <router-link to="/work">Work</router-link>
                <router-link to="/services">Services</router-link>
            </div>
            <div class="secondary">
                <router-link class="contact button-secondary" to="/contact">Contact</router-link>
                <button class="profile-icon" @click="showProfileDropdown = !showProfileDropdown">
                    <img alt="Profile Icon" src="../assets/default-login-icon.png">
                    <ul class="profile-dropdown" v-if="showProfileDropdown">
                        <div class="logged-out" v-if="!this.$store.state.authenticated">
                            <li>
                                <router-link to="/login">Login</router-link>
                            </li>
                            <li>
                                <router-link to="/register">Register</router-link>
                            </li>
                        </div>
                        <div class="logged-in" v-if="this.$store.state.authenticated">
                            <li>
                                <router-link to="/account">Account</router-link>
                            </li>
                            <li>
                                <router-link to="/logout">Logout</router-link>
                            </li>
                        </div>
                    </ul>
                </button>
            </div>
        </div>

        <div class="mobile-links">
            <font-awesome-icon icon="fa-solid fa-bars" @click="toggleMobileLinks" />

            <div class="dropdown" v-if="showMobileLinks">
                <router-link to="/" @click="toggleMobileLinks">Home</router-link>
                <router-link to="/about" @click="toggleMobileLinks">About</router-link>
                <router-link to="/work" @click="toggleMobileLinks">Work</router-link>
                <router-link to="/services" @click="toggleMobileLinks">Services</router-link>
                <router-link to="/contact" @click="toggleMobileLinks">Contact</router-link>
                <router-link to="/login" @click="toggleMobileLinks"  v-if="!this.$store.state.authenticated">Login</router-link>
                <router-link to="/account" @click="toggleMobileLinks" v-if="this.$store.state.authenticated">Account</router-link>
                <router-link to="/logout" @click="toggleMobileLinks" v-if="this.$store.state.authenticated">Logout</router-link>
            </div>
        </div>

    </nav>
</template>

<script>
    export default {
        name: 'HeaderNav',
        data() {
            return {
                showMobileLinks: false,
                showProfileDropdown: false
            }
        },
        methods: {
            toggleMobileLinks() {
                this.showMobileLinks = !this.showMobileLinks;
            }
        }
    }
</script>

<style lang="scss" scoped>
    nav {
        background:white;
        padding: 0px;
        align-items: center;
        flex-grow: 1;
        flex-basis: auto;
        z-index: 100;
        position: fixed;
        width:100%;
        top:0;
        border-bottom:1px solid #c1c1c1;

        .brand {
            img {
                &.logo {
                    width:115px;
                    margin:15px 30px;
                }
            }
        }

        .mobile-links {
            display:none;
            flex-grow: 1;
            text-align: right;
            margin-right:30px;

            svg {
                font-size:1.5em;
                color:#000;

                &:hover {
                    cursor: pointer;
                    color:#025bf7;
                }
            }

            .dropdown {
                position: fixed;
                left:0;
                top: 65px;
                background:#025bf7;
                width:100%;
                height: 100vh;

                a {
                    display: block;
                    text-align: center;
                    font-size:38px;
                    margin:40px 20px;
                    color: white;
                    font-weight: 800;
                    text-transform: uppercase;

                    &:hover {
                        color:black;
                    }

                }
            }
        }

        .links {
                flex-grow: 1;
                flex-basis: auto;

                .primary,
                .secondary {

                    a {
                        margin-right:30px;
                        color: #000;
                        text-decoration: none;
                        font-size: 14px;
                        text-transform: uppercase;
                    }
                    
                }
                
                .primary{
                    margin-right:auto;

                    a {
                        &.router-link-exact-active {
                            color: $brandPrimary;
                        }
                    }
                }

                .secondary {
                    display: flex;
                    align-items: center;

                    .profile-icon {
                        background:none;
                        border:none;

                        img {
                            height: 30px;
                            width: 30px;
                            cursor: pointer;
                        }

                        .profile-dropdown {
                            position: absolute;
                            background:none;
                            border:none;
                            width:150px;
                            top: 0px;
                            right:0;
                            background:white;
                            border-radius: 10px;
                            padding:10px;
                            border: 1px solid #cecece;

                            li {
                                text-align: left;
                                margin:0;
                                padding:0;

                                &:before {
                                    content:'';
                                    margin:0px;
                                }

                                a {
                                    display:block;
                                    padding:5px;
                                    margin:5px;
                                    border-radius: 6px;

                                    &:hover {
                                        background:#e5e8ea;
                                    }
                                }
                            }
                        }
                    }
                }
            }
    }
</style>