<template>
    <div id="error-modal">
        <p>{{ msg }}</p>
    </div>
</template>

<script>
    export default {
        name:'ErrorModal',
        props: ['msg']
    }
</script>

<style lang="scss" scoped>
#error-modal {
    background:#FE0018;
    color:white;
    font-size:16px;
    position:fixed;
    bottom:20px;
    right:20px;
    padding:10px;
}
</style>