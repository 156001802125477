<template>
    <div id="contact-page">
        <section id="header" class="container">
            <h1 class="huge">Need <span class="primary">Help</span>? We are <br> here for you</h1>
            <p>
                We are on standby ready for your request
            </p>

            <img class="message-sent-img" src="../assets/3-stars.png" alt="" v-if="formSent" ref="messageSentImg">
            <p class="message-sent" v-if="formSent" ref="messageSent">
                Message Sent.  We are taking a look at it and will be back with you shortly!
            </p>

            <form @submit="doContactForm" v-if="!formSent">
                <div>
                    <input type="text" :class="{ error: formData.name.error }" v-model="formData.name.value" placeholder="Name" ref="name">
                </div>

                <div>
                    <input type="email" :class="{ error: formData.email.error }" v-model="formData.email.value" placeholder="Email" ref="email">
                </div>

                <div>
                    <select v-model="formData.subject.value" :class="{ error: formData.subject.error }" ref="subject">
                        <option disabled selected value="">Choose Subject...</option>
                        <option value="new-project">New Project Inquiry</option>
                        <option value="general">General Question</option>
                        <option value="feedback">Feedback</option>
                        <option value="business">Business Inquires</option>
                    </select>
                </div>

                <div>
                    <textarea cols="30" rows="10" :class="{ error: formData.message.error }" v-model="formData.message.value" placeholder="Enter Message..." ref="message"></textarea>
                </div>

                <button class="button-primary" :class="{ 'button-loading': isSending }" ref="sendBtn"><span>Send Message</span></button>

                <div class="errors" v-if="errors.length > 0">
                    <p v-for="(item, index) in errors" :key="index">{{ item.msg }}</p>
                </div>

            </form>

        </section>

    </div>
</template>

<script>

    import Validator from 'validator'
    import Message from '@/classes/Message.js'
    import MessageService from '@/services/MessageService'
    
    export default {
        name: 'ContactView',
        data() {
            return {
                sendBtn: null,
                formData: new Message(),
                isSending: false,
                formSent: false,
                errors: []
            }
        },
        methods: {
            doContactForm(e) {
                e.preventDefault();
                this.isSending = true;
                this.resetErrors()
                // this.validateForm()
                if (!this.hasErrors()) {
                    this.sendData()
                } else {
                    this.showErrors()
                }
            },
            validateForm() {
                this.formData.name.value = Validator.escape(this.formData.name.value).trim()
                this.formData.email.value = Validator.escape(this.formData.email.value).trim();
                this.formData.subject.value = Validator.escape(this.formData.subject.value).trim()
                this.formData.message.value = Validator.escape(this.formData.message.value).trim()
                if (this.formData.email.value == null || this.formData.email.value == '' || !Validator.isEmail(this.formData.email.value)) {
                    this.errors.push({ field: 'email', msg: 'Email must be filled out and valid.' })
                }
                if(this.formData.name.value == '' || this.formData.name.value == null) {
                    this.errors.push({field:'name', msg:'Name must be filled out.'})
                }
                if(this.formData.subject.value == '' || this.formData.subject.value == null) {
                    this.errors.push({field:'subject', msg:'A subject must be selected.'})
                }
                if(this.formData.message.value == '' || this.formData.message.value == null) {
                    this.errors.push({field:'message', msg:'You must enter a message.'})
                }
            },
            hasErrors() {
                if(this.errors.length > 0) {
                    return true;
                }
                return false;
            },
            showErrors(){
                let _this = this;
                this.isSending = false;
                this.errors.forEach(err => {
                    if(_this.formData.hasOwnProperty(err.field)){
                        _this.formData[err.field].error = true;
                    }
                })
            },
            resetErrors() {
                this.errors = [];
                this.formData.name.error = false;
                this.formData.email.error = false;
                this.formData.subject.error = false;
                this.formData.message.error = false;
            },
            async sendData() {
                let _this = this;

                await MessageService.Create(this.formData.serialize())
                .then(res => {
                    this.sentSuccessfully();
                })
                .catch(err => {
                    let _this = this;
                    if (Object.keys(err.response.data).length > 0) {
                        Object.keys(err.response.data).forEach(errorKey => {
                            if (err.response.data[errorKey] != '') {
                                _this.errors.push({ field: errorKey, msg: err.response.data[errorKey] })
                            }
                            _this.showErrors()
                        })
                    } else {
                        _this.errors.push({ msg: 'Error Processing Form. Please contact us directly.' })
                    }
                })

                this.isSending = false;
            },
            sentSuccessfully() {
                let _this = this;
                this.formSent = true;
                setTimeout(function() {
                    _this.$refs.messageSent.classList.add('active')
                    _this.$refs.messageSentImg.classList.add('active')
                }, 10)
            }
        }
    }
</script>

<style lang="scss" scoped>
#contact-page {
  .container {

    h1,
    h2 {
      text-align: center;
    }

    p{
      text-align: center;
    }
  }

  form {
    max-width:550px;
    margin:30px auto 0 auto;

    button {
        display:block;
        margin:0 auto 20px auto;
    }
  }
}
</style>