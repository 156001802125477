/* eslint-disable */
import axios from 'axios';

const url = '/api/reset-password';

class ResetPasswordService {

    static GeneratePasswordResetToken(email) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.post(url, { email: email });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }
    
    static VerifyPasswordResetTokenExists(token) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(url + '/' + token);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }
    
    static ResetPassword(token, password) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.put(url + '/' + token, { password: password });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

}

export default ResetPasswordService;