<template>
    <div id="confirm-popup">
        <div class="popup-container">

            <div v-if="paymentMade">
                <img class="message-sent-img" src="../assets/3-stars.png" alt="" ref="messageSentImg">
                <p class="message-sent" ref="messageSent">
                    Thanks for your payment!
                </p>
                <button @click="doCancel" class="button-red">Close</button>
            </div>

            <div v-if="!showAddCard && !paymentMade">
                <p v-if="isSubscription">Do you wish to proceed with the {{ name }} subscription? You will be charged ${{ price.unit_amount / 100 }}/{{ price.recurring.interval }} starting today.</p>
                <p v-else>Do you wish to proceed with the {{ name }} payment? You will be charged ${{ price.amount / 100 }}.</p>
            
                <div class="payment-list">
                    <p class="text-small">Choose or add your payment method.</p>
                    <StripeAvailCards @selectedSource="updateSelectedSource"></StripeAvailCards>
                    <button class="button-primary" @click="doAddCard">Add Card</button>
                </div>
            
                <button @click="doCancel" class="button-red">Cancel</button>
                <button 
                    v-if="isSubscription"
                    @click="doSubscribe" 
                    class="button-primary" 
                    :disabled="selectedPaymentSource == null || isSending" 
                    :class="{ 'button-loading': isSending }">
                    <span>Pay Now</span>
                </button>
                <button 
                    v-else
                    @click="doInvoice" 
                    class="button-primary" 
                    :disabled="selectedPaymentSource == null || isSending" 
                    :class="{ 'button-loading': isSending }">
                    <span>Pay Now</span>
                </button>

                <div class="errors" v-if="errors.length > 0">
                    <p v-for="(item, index) in errors" :key="index">{{ item.msg }}</p>
                </div>
            </div>

            <StripeAddCard @close="showAddCard = false" v-if="showAddCard && !paymentMade"></StripeAddCard>

        </div>
    </div>
</template>

<script>

    import InvoiceService from '@/services/InvoiceService'
    import SubscriptionService from '@/services/SubscriptionService'
    import StripeAddCard from '@/components/account/StripeAddCard'
    import StripeAvailCards from '@/components/account/StripeAvailCards';

    export default {
        name:'PaymentConfirmPopup',
        components: {
            StripeAddCard,
            StripeAvailCards
        },
        props: ['price', 'name', 'isSubscription'],
        data() {
            return {
                errors: [],
                showAddCard: false,
                selectedPaymentSource: null,
                isSending: false,
                paymentMade: false
            }
        },
        methods: {
            doCancel() {
                if(this.paymentMade) {
                    if (this.isSubscription) {
                        this.$store.dispatch('getSubscriptions');
                    }
                    this.$emit('onPaymentMade');
                }
                this.$emit('close')
            },
            updateSelectedSource(source) {
                this.selectedPaymentSource = source;
            },
            doAddCard() {
                this.showAddCard = true;
                this.selectedPaymentSource = null;
            },
            async doSubscribe() {
                this.errors = [];
                this.isSending = true;
                let obj = {source: this.selectedPaymentSource, price: this.price}
                await SubscriptionService.Subscribe(obj)
                .then(data => {
                    this.doCompleted(data)
                })
                .catch(err => {
                    if (err.response.data.code === 'resource_missing') {
                        this.errors.push({ msg: 'No payment method available. Please add a card to pay.' })
                    } else {
                        this.errors.push({ msg: 'There was an error processing payment.  Please ' })
                    }
                })
                this.isSending = false;
            },
            async doInvoice() {
                this.errors = [];
                this.isSending = true;
                let obj = { source: this.selectedPaymentSource, invoice: this.price }
                await InvoiceService.Pay(obj)
                    .then(data => {
                        this.doCompleted(data)
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response.data.code === 'resource_missing') {
                            this.errors.push({ msg: 'No payment method available. Please add a card to pay.' })
                        } else if (err.response.data.type === 'StripeInvalidRequestError') {
                            this.errors.push({ msg: err.response.data.raw.message })
                        } else {
                            this.errors.push({ msg: 'There was an error processing payment.  Please ' })
                        }
                    })
                this.isSending = false;
            },
            doCompleted(data) {
                this.paymentMade = true;
                this.showAddCard = false;
                let _this = this;
                setTimeout(function () {
                    _this.$refs.messageSent.classList.add('active')
                    _this.$refs.messageSentImg.classList.add('active')
                }, 10)
                // do completed stuff, maybe show payment complete dialog 
            }
        }
    }
</script>

<style lang="scss" scoped>
#confirm-popup {
    background:rgba(0,0,0,0.8);
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    z-index:1000;
    text-align: center;
    .popup-container {
        position: fixed;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%);
        width:60%;
        max-width:500px;
        background:white;
        z-index:100;
        padding:20px;
        border-radius: 20px;


        button {
            margin:10px;
        }

        .payment-list {
            background:#c8dfff;
            border-radius: 6px;
            padding:5px;
        }
    }
}
</style>