<template>
    <div id="work-page">
        <section id="header" class="container">
            <h1 class="huge">We are <span class="primary">proud</span> of <br> the results we deliver</h1>
            <p>
                Take a look at what we have created
            </p>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'WorkView'
    }
</script>

<style lang="scss" scoped>
#work-page {
  .container {

    h1,
    h2 {
      text-align: center;
    }

    p{
      text-align: center;
    }
  }
}
</style>