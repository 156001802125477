<template>
    <div id="services-page">
        <section id="heading" class="container">
            <h1 class="huge">We <span class="primary">shine</span> in creating <br> websites and graphics</h1>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'ServicesView'
    }
</script>

<style lang="scss" scoped>
#services-page {
  .container {

    h1,
    h2 {
      text-align: center;
    }

    p{
      text-align: center;
    }
  }
}
</style>