export default class User {

    email = '';
    firstName = '';
    lastName = '';
    phone = '';
    company = '';
    country = '';
    address = '';
    suite = '';
    city = '';
    state = '';
    zip = '';
    subscriptions = [];
    
}