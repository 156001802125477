<template>
    <div id="login-page">

        <section id="login" class="container">
            <h1 class="huge">Login</h1>
            <p>If this is your first time logging in, please check for the credentials we have sent you.</p>
            <p>You will be prompted to change your password on your first login.</p>
            <form @submit="doLogin">
                <div>
                    <input type="email" :class="{error: formData.email.error }" v-model="formData.email.value" placeholder="Email" ref="email" autocomplete>
                </div>
                <div>
                    <input type="password" :class="{ error: formData.password.error }" v-model="formData.password.value" placeholder="Password" ref="password" autocomplete>
                </div>

                <button class="button-primary" :class="{'button-loading': isSending}"><span>Login</span></button>

                <div class="errors" v-if="errors.length > 0">
                    <p v-for="(item, index) in errors" :key="index">{{ item.msg }}</p>
                </div>

            </form>
            <p class="small">Don't remember your password? <router-link to="/forgot-password">Request a new one.</router-link></p>
            <p class="small">Need to sign up? <router-link to="/register">Register</router-link></p>
        </section>

    </div>
</template>

<script>
    import AuthService from '@/services/AuthService'
    import Validator from 'validator'

    export default {
        name: 'LoginView',
        data() {
            return {
                formData: {
                    email: {
                        value: '',
                        error: false
                    },
                    password: {
                        value: '',
                        error: false
                    }
                },
                isSending: false,
                errors: []
            }
        },
        methods: {
            doLogin(e) {
                e.preventDefault();
                this.isSending = true;
                this.resetErrors()
                this.validateForm()
                if (!this.hasErrors()) {
                    this.sendData()
                } else {
                    this.showErrors()
                }
            },
            validateForm() {
                let email = Validator.escape(this.formData.email.value).trim();
                if(!Validator.isEmail(email)) {
                    this.errors.push({ field: 'email', msg: 'Email must be filled out.' })
                }
                if(this.formData.password.value.trim() == '' || this.formData.password.value == null) {
                    this.errors.push({ field: 'password', msg: 'Password must be filled out.' })
                }
            },
            hasErrors() {
                if(this.errors.length > 0) {
                    return true;
                }
                return false;
            },
            showErrors(){
                let _this = this;
                this.isSending = false;
                this.errors.forEach(err => {
                    if(_this.formData.hasOwnProperty(err.field)){
                        _this.formData[err.field].error = true;
                    }
                })
            },
            resetErrors() {
                this.errors = [];
                this.formData.email.error = false;
                this.formData.password.error = false;
            },
            async sendData() {
                this.isSending = false;
                await AuthService.Login(this.serializeFormData())
                .then((res) => {
                    if(res.data.token) {
                        let obj = {
                            token: res.data.token,
                            expires: res.data.expires
                        };
                        this.$store.commit('setToken', obj);
                    }
                    this.$router.push({ name: 'account' })
                })
                .catch((err) => {
                    if(err.response.status == 401){
                        if(err.response.data.error) {
                            this.errors.push({ msg: err.response.data.error })
                        } else {
                            this.errors.push({ msg: 'Invalid Login Credentials.' })
                        }
                    } else {
                        this.errors.push({ msg: 'System Error. Please contact us.' })
                    }
                })
            },
            serializeFormData() {
                return {
                    email: this.formData.email.value,
                    password: this.formData.password.value
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
#login-page {
  .container {

    h1,
    h2 {
      text-align: center;
    }

    p{
      text-align: center;
    }
  }

  form {
    max-width:550px;
    margin:30px auto 0 auto;

    button {
        display:block;
        margin:0 auto 20px auto;
    }
  }
}
</style>