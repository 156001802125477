<template>
    <div>
        <section class="address">

            <div v-if="updated" class="success-modal">
                <img class="message-sent-img active" src="../assets/3-stars.png" alt="" ref="messageSentImg">
                <p class="message-sent active" ref="messageSent">
                    Profile Information has been updated!
                </p>
            </div>

            <h2>Billing Information</h2>
            <form @submit.prevent="doSubmit">
                <button class="button button-secondary edit-btn" v-show="!isEditing" @click.prevent="doEdit">Edit</button>
                <div class="flex">
                    <div class="form-group">
                        <label class="strong">First Name:</label>
                        <input type="text" :class="{ error: formData.firstName.error }" v-model="formData.firstName.value" :disabled="!isEditing">
                    </div>
                    <div class="form-group">
                        <label class="strong">Last Name:</label>
                        <input type="text" :class="{ error: formData.lastName.error }" v-model="formData.lastName.value" :disabled="!isEditing">
                    </div>
                </div>
            
                <label class="strong">Company:</label>
                <p v-if="!formData.company.value && !isEditing">N/A</p>
                <input type="text" :class="{ error: formData.company.error }" v-model="formData.company.value" :disabled="!isEditing">

                <div class="flex">
                    <div class="form-group">
                        <label class="strong">Phone:</label>
                        <input type="text" :class="{ error: formData.phone.error }" v-model="formData.phone.value" :disabled="!isEditing">
                    </div>
                    <div class="form-group">
                        <label class="strong">Email:</label>
                        <p>{{ this.$store.state.user.email }}</p>
                    </div>
                </div>

                <div class="form-group">
                    <label class="strong">Country:</label>
                    <input type="text" :class="{ error: formData.country.error }" v-model="formData.country.value" :disabled="!isEditing">
                </div>

                <div class="form-group">
                    <label class="strong">Address: </label>
                    <input type="text" :class="{ error: formData.address.error }" v-model="formData.address.value" :disabled="!isEditing">
                </div>

                <div class="form-group">
                    <label class="strong">Suite/Apt: </label>
                    <p v-if="!formData.suite.value && !isEditing">N/A</p>
                    <input type="text" :class="{ error: formData.suite.error }" v-model="formData.suite.value" :disabled="!isEditing">
                </div>

                <div class="flex">
                    <div class="form-group">
                        <label class="strong">City:</label>
                        <input type="text" :class="{ error: formData.city.error }" v-model="formData.city.value" :disabled="!isEditing">
                    </div>
                    <div class="form-group">
                        <label class="strong">State:</label>
                        <input type="text" :class="{ error: formData.state.error }" v-model="formData.state.value" :disabled="!isEditing">
                    </div>
                    <div class="form-group">
                        <label class="strong">Zip Code:</label>
                        <input type="text" :class="{ error: formData.zip.error }" v-model="formData.zip.value" :disabled="!isEditing">
                    </div>
                </div>

                <button class="button button-red" v-show="isEditing" @click.prevent="resetForm" :disabled="isSending">Cancel Edit</button>
                <button v-if="isEditing" class="button-primary" :class="{ 'button-loading': isSending }" :disabled="isSending"><span>Update</span></button>
                
                <div class="errors" v-if="errors.length > 0">
                    <p v-for="(item, index) in errors" :key="index">{{ item.msg }}</p>
                </div>
                
            </form>

        </section>
    </div>
</template>

<script>

    import ProfileInformation from '@/classes/ProfileInformation'
    import UserService from '@/services/UserService'

    export default {
        name:'AddressForm',
        props: ['type'],
        data(){
            return {
                isEditing: false,
                isSending:false,
                updated: false,
                errors: [],
                formData: new ProfileInformation(this.$store.state.user)
            }
        },
        methods: {
            resetForm() {
                this.isEditing = false;
                this.isSending = false;
                this.updated = false;
                this.errors = [];
                this.formData = new ProfileInformation(this.$store.state.user)
            },
            doEdit(){
                this.isEditing = true;
                this.isSending = false;
                this.updated = false;
                this.errors = [];
            },
            doSubmit() {
                this.isSending = true;
                this.resetErrors()
                this.validateForm()
                if (!this.hasErrors()) {
                    this.sendData()
                } else {
                    this.showErrors()
                }
            },
            validateForm() {
                for(let key in this.formData) {
                    let value = this.formData[key];
                    if(value.required && (value.value.trim() == '' || value.value == null)) {
                        this.errors.push({ field: key, msg: key + ' must be filled out.' })
                    }
                }
            },
            hasErrors() {
                if(this.errors.length > 0) {
                    return true;
                }
                return false;
            },
            showErrors(){
                let _this = this;
                this.isSending = false;
                this.errors.forEach(err => {
                    if(_this.formData.hasOwnProperty(err.field)){
                        _this.formData[err.field].error = true;
                    }
                })
            },
            resetErrors() {
                this.errors = [];
                let _this = this;
                Object.keys(this.formData).forEach(key => {
                    _this.formData[key].error = false;
                })
            },
            async sendData() {
                await UserService.Update(this.formData.serialize())
                .then((res) => {
                    this.doCompleted(res)
                })
                .catch(err => {
                    this.isSending = false;
                    let _this = this;
                    if(Object.keys(err.response.data).length > 0) {
                        Object.keys(err.response.data).forEach(errorKey => {
                            if (err.response.data[errorKey] != '') {
                                _this.errors.push({ field: errorKey, msg: err.response.data[errorKey] })
                            }
                            _this.showErrors()
                        })
                    } else {
                        console.log(err);
                        _this.errors.push({ msg: 'Error Processing Registration. Please contact us directly.' })
                    }
                })
            },
            doCompleted(res){
                let _this = this;
                this.isSending = false;
                this.updated = true;
                this.isEditing = false;
                this.$store.commit('updateState', res.data);

                setTimeout(function () {
                    _this.$refs.messageSent.classList.add('active')
                    _this.$refs.messageSentImg.classList.add('active')
                }, 10)

                setTimeout(function () {
                    _this.updated = false;
                }, 3000)
            }
        }
    }
</script>

<style lang="scss" scoped>

.success-modal {
    position: fixed;
    z-index:1000;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.address {
    form {
        position: relative;
        .edit-btn {
            position: absolute;
            top: 20px;
            right: 20px;
        }
        .button-red {
            margin-right: 10px;
        }

        p {
            margin:0 10px;
            font-size:18px;
        }

        .flex {
            flex-direction: row;
        }

        label {
            display:block;
            font-weight: 600;
            margin-left:10px;
            font-style: normal;
        }

        input {
            width:100%;
            box-sizing: border-box;

            &:disabled {
                background:none;
                border:none;
                padding:0;
                margin-left:10px;
                border-radius: 0;
            }
        }
    }
}
</style>