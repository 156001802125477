<template>
    <section id="intro">
        <div class="container">
    
            <div class="hero-heading">
                <h1 class="huge text-center">Quality <span class="primary">Websites</span> for<br/> <span class="squiggly"> Small</span> Businesses.</h1>
                <!-- <img class="left-img" src="../../assets/phone-art.png">
                <img class="right-img" src="../../assets/computer-art.png"> -->
            </div>
        
            <p class="text-center">
                Websites and web applications built from scratch by a professional with 20+ years of experience. Our websites are optimized, fast 
                loading and increase on-site engagement.
            </p>

            <div class="list-container flex">
                <div class="flex-1">
                    <img alt="Featured Work Image" src="../../assets/featured-work.png">
                </div>
                <div class="flex-1">

                    <ul>
                        <li>Attract new leads</li>
                        <li>Fast load times retain more users</li>
                        <li>Modern, responsive designs showcase your business</li>
                        <li>Contact form for business inquiries</li>
                        <li>Unlimited content changes and minor updates</li>
                        <li>24/7 Support</li>
                    </ul>

                    <div class="get-started">
                        <router-link class="button-secondary" to="/">
                            <div>Get Started</div>
                        </router-link>
                    </div>

                </div>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'IntroSection'
    }
</script>

<style lang="scss" scoped>
section#intro {
    .container {
        .hero-heading {
            position: relative;
            text-align: center;
            max-width:950px;
            margin:0 auto;

            img {
                position: absolute;
                overflow: hidden;
                opacity: 0.4;
                z-index:1;

                &.left-img {
                    top:-20px;
                    left:-93px;
                }

                &.right-img {
                    right:-118px;
                    top:270px;
                }
            }
        }

        span.squiggly {
            display: inline-block;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                width:100%;
                height:20px;
                bottom:-15px;
                left:0;
                // background: url(../../assets/squiggly.png) no-repeat;
                background-size: 100%;
            }
        }

        p {
            margin: 60px auto 0 auto;
            max-width:1000px;
        }

        .list-container {
            align-items: center;
            max-width:80%;
            margin:60px auto 0 auto;

            > div {
                padding:20px;
            }

            img {
                max-height:400px;
                max-width:600px;
                width: 100%;
                margin: 0 auto;
            }

            ul {
                margin-top:0px;

                li {
                    font-weight: 800;
                }
            }

            .get-started {
                text-align: left;
                margin-left:15px;

                a {
                    font-size: 1.2em;

                    &:hover {
                        padding:5px 50px 5px 20px;

                        div {

                            &:after {
                                opacity: 1;
                                transform: translateX(10px);
                            }
                        }
                    }

                    div {
                        transition: all 0.2s linear;

                        &:after {
                            font-weight: 300;
                            transition: all 0.2s linear;
                            transform: translateX(-30px);
                            position: absolute;
                            content: '>>';
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    section#intro {
            .container {

            .hero-heading {
                max-width:750px;

                img {
                    top: 0px;

                    &.left-img {
                        top:-20px;
                        left:-50px;
                        width:70px;
                    }

                    &.right-img {
                        right:-50px;
                        top:70px;
                        width:120px;
                    }
                }
            }

            .list-container {
                max-width: 100%;
            }

        }
    }
}

@media (max-width: 960px) {
    section#intro {
        .container {
            .hero-heading {
                max-width:520px;

                img {
                    top: 0px;

                    &.left-img {
                        top:-20px;
                        width:50px;
                        left:-30px;
                    }

                    &.right-img {
                        top:50px;
                        width:80px;
                        right:-20px;
                    }
                }
            }

            .list-container {

                .get-started {
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    section#intro {
        .container {
            .hero-heading {
                margin-bottom:30px;
                img {
                    display: none;
                }
            }

            span.squiggly {
                &:before {
                    top:25px;
                }
            }
        }
    }
}
</style>