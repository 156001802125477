/* eslint-disable */
import axios from 'axios';
import store from '../store';


const url = '/api/subscription';
const cancelUrl = '/api/subscription/cancel';
const productsUrl = '/api/subscription/products';

class SubscriptionService {

    //Get all products
    static ListProducts() {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(productsUrl, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    // Subscribe to Product
    static Subscribe(obj) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.post(url, obj, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    //Get Account Subscriptions
    static List(obj) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(url, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    //Request to Cancel Account Subscription
    static RequestCancellation(obj) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.post(cancelUrl, obj, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    //Request to Cancel Account Subscription
    static ListCancellationRequests(obj) {
        return new Promise(async (resolve, reject) => {
            try {
                const token = store.state.token;
                const res = await axios.get(cancelUrl, { headers: { Authorization: token } });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

}

export default SubscriptionService;